import { Activity } from "@sequel-care/types"
import { PatientWithMetrics } from "@sequel-care/types/Patient"
import { Tooltip } from "components/common"
import DashboardCard from "components/DashboardCard"
import InfoCircle from "components/icons/InfoCircle"
import { ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { BarVerticalChart, BarVerticalChartWithInteractions } from "../ChartComponents"
import { CountObj, DashboardFilters } from "../types"
import { filterPatientsByMetric, useMetricValueToTitle } from "../utils"

export const ActivityCard = ({
    patients: allPatients,
    withInteractions,
    emptyState,
    loading
}: {
    patients: PatientWithMetrics<string>[]
    withInteractions?: boolean
    filters: DashboardFilters.Global
    emptyState?: ReactNode
    loading: boolean
}) => {
    const { t } = useTranslation("therapist")

    const [countByActivity, setCountByActivity] = useState<CountObj<Activity>[]>([
        { metricValue: "active", count: 0 },
        { metricValue: "inactive", count: 0 }
    ])

    const metricValueToTitle = useMetricValueToTitle("activity")

    useEffect(() => {
        setCountByActivity(
            countByActivity.map(({ metricValue }) => ({
                metricValue,
                count: filterPatientsByMetric(allPatients, "activity", metricValue).length
            }))
        )
    }, [allPatients])

    const BarComponent = withInteractions ? BarVerticalChartWithInteractions : BarVerticalChart

    return (
        <DashboardCard
            title={
                loading ? (
                    <Skeleton className="w-32" />
                ) : (
                    <>
                        {t("dashboard.activity.title")}{" "}
                        <Tooltip placement="top" content={t("dashboard.activity.info")}>
                            <InfoCircle className="w-5 h-5" />
                        </Tooltip>
                    </>
                )
            }
            subtitle={loading ? <Skeleton className="w-64" /> : t("dashboard.activity.subtitle")}
            className="flex flex-col"
            contentClassName="flex justify-center gap-12 grow pb-3"
        >
            {emptyState
                ? emptyState
                : countByActivity.map(({ metricValue, count }) => (
                      <BarComponent
                          loading={loading}
                          key={metricValue}
                          color={metricValue === "active" ? "text-med-blue" : "text-secondary"}
                          label={t(`dashboard.activity.${metricValue}`)}
                          fullHeight={170}
                          value={count}
                          value_out_of={allPatients.length}
                          filterdata={
                              !withInteractions
                                  ? undefined
                                  : { key: "activity", value: metricValue, title: metricValueToTitle(metricValue) }
                          }
                      />
                  ))}
        </DashboardCard>
    )
}
